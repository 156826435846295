import {Injectable, signal, WritableSignal} from '@angular/core';
import {Auth} from "@app/models/auth";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  authentication: WritableSignal<Auth> = signal({} as Auth);

  constructor(private router: Router) {
    const auth = {} as Auth;
    auth.token = localStorage.getItem('token') ?? undefined;
    const expiry = localStorage.getItem('expiry');
    if (expiry) {
      auth.expiration = new Date(expiry);
    }
    this.authentication.set(auth);
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('expiry');
    this.router.navigate(['login']).then();
  }

  login(auth: Auth) {
    if (auth.expiration && auth.token) {
      auth.expiration = new Date(auth.expiration);
      this.authentication.set(auth);
      localStorage.setItem('token', auth.token);
      localStorage.setItem('expiry', auth.expiration.toString());
      this.router.navigate(['properties']).then();
    }
  }

}
